@font-face {
  font-family: 'morvarid';
  src: local('morvarid'), url(../../fonts/morvarid.ttf) format('truetype');
}


.main {
  /* font-family: 'Noto Sans JP' !important; */
  font-family:  'morvarid', 'Heebo', sans-serif !important;
  /* direction: "rtl", */
}